/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'redux/types';
import { withErrorAndEmptyResponseHandler } from 'redux/utils';
import {
  passValvesResourceService,
  preWorkItemsResourceService,
  procedureApprovalFilesResourceService,
  procedureAttachmentsResourceService,
  procedureReviewCommentsService,
  projectsResourceService,
  workOrdersResourceService,
} from 'resources';
import { projectStateService } from 'services';
import {
  AssetMappingType,
  Comment,
  KeyValuePair,
  PnId,
  PreWorkItem,
  ProcedureApprovalFile,
  ProcedureFileAttachment,
  Project,
  ProjectStatus,
} from 'types';

export const fetchProject = createAsyncThunk(
  'project/fetchProject',
  withErrorAndEmptyResponseHandler((projectId: string) => {
    return projectsResourceService.fetchProject(projectId);
  })
);

export const fetchPassValves = createAsyncThunk(
  'project/fetchPassValves',
  async (ids: number[]) => {
    if (!ids || !ids.length) {
      return Promise.resolve([]);
    }
    const passingValves = await passValvesResourceService.fetchPassValves(ids);
    return passingValves;
  }
);

export const fetchWorkorders = createAsyncThunk(
  'project/fetchWorkorders',
  async (ids: number[]) => {
    if (!ids || !ids.length) {
      return Promise.resolve([]);
    }
    const workOrders =
      workOrdersResourceService.fetchWorkordersForAssetIds(ids);
    return workOrders;
  }
);

export const fetchReviewComments = createAsyncThunk(
  'project/fetchReviewComments',
  async (projectId: string) => {
    const reviewComments =
      await procedureReviewCommentsService.fetchReviewComments(projectId);
    return reviewComments;
  }
);

export const fetchPreWorkItems = createAsyncThunk(
  'workSteps/fetchPreWorkItems',
  async (projectId: string) => {
    const preWorkItems = await preWorkItemsResourceService.fetchPreWorkItems(
      projectId
    );
    return preWorkItems;
  }
);

export const fetchAttachments = createAsyncThunk(
  'project/fetchAttachments',
  async (projectId: string | undefined) => {
    if (!projectId) {
      return [];
    }

    const attachmentsList =
      await procedureAttachmentsResourceService.fetchAttachments(projectId);
    return attachmentsList;
  }
);

export const fetchApprovalFiles = createAsyncThunk(
  'project/fetchApprovalFiles',
  async (projectId: string | undefined) => {
    if (!projectId) {
      return [];
    }

    const approvalFilesList =
      await procedureApprovalFilesResourceService.fetchApprovalFiles(projectId);

    return approvalFilesList as ProcedureApprovalFile[];
  }
);

const initialState = projectStateService.getInitialState();

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    selectProject: (_state, action: PayloadAction<Project>) => {
      return projectStateService.setProject(action.payload);
    },
    createProject: (
      _state,
      action: PayloadAction<{
        asset: AssetMappingType;
        user: string;
        newId: string;
        selectedRootAsset: string;
      }>
    ) => {
      const { asset, user, newId, selectedRootAsset } = action.payload;
      return projectStateService.createProject(
        asset,
        user,
        newId,
        selectedRootAsset
      );
    },
    loadAndCreateProject: (
      _state,
      action: PayloadAction<{
        procedure: Project;
        newId: string;
        newUser: string;
      }>
    ) => {
      return projectStateService.loadAndCreateProject(
        action.payload.procedure,
        action.payload.newUser,
        action.payload.newId
      );
    },
    makeProjectNotNew: (state, action: PayloadAction<{ pnids: string[] }>) => {
      const pnids = action.payload.pnids.map((id) => {
        return { id };
      }) as Array<PnId>;
      state.newProject = false;
      state.project = {
        ...state.project,
        status: 'compilation',
        pnIds: pnids,
      };
    },
    setIsProcedureLoaded: (state) => {
      state.isProcedureLoaded = true;
    },
    setProjectNeedsMigration: (
      state,
      action: PayloadAction<{
        filesIdsMap: KeyValuePair;
        pnIdsDrawings: KeyValuePair;
      }>
    ) => {
      const { filesIdsMap, pnIdsDrawings } = action.payload;
      const pnids = state.project.pnIds
        ? state.project.pnIds?.map((pnid) => pnid.id.toString())
        : [];

      for (const key in filesIdsMap) {
        const index = pnids.indexOf(key.toString());
        if (index !== -1) {
          pnids[index] = filesIdsMap[key].toString();
        }
      }

      state.project = {
        ...state.project,
        pnIds: pnids.map((id) => {
          return { id };
        }) as Array<PnId>,
        pnIdsDrawings,
        needsMigration: true,
      };
    },
    setProjectMigrationCompleted: (state) => {
      state.project = {
        ...state.project,
        needsMigration: false,
      };
    },
    resolveComments: (
      state,
      action: PayloadAction<{ resolved?: { name: string; date: number } }>
    ) => {
      if (state.project.status === 'compilation') {
        if (state.project.resolved) {
          state.project.resolved.compiler = action.payload.resolved;
        } else {
          state.project.resolved = {
            compiler: action.payload.resolved,
          };
        }
      } else if (state.project.status === 'review') {
        if (state.project.resolved) {
          state.project.resolved.reviewer = action.payload.resolved;
        } else {
          state.project.resolved = {
            reviewer: action.payload.resolved,
          };
        }
      }
    },
    stopProjectExecution: (
      state,
      action: PayloadAction<{
        executionStopped?: { name: string; date: number };
      }>
    ) => {
      state.project.status = 'compilation';
      state.project.executionStopped = action.payload.executionStopped;
    },
    updateProjectMethodAndObjectives: (
      state,
      action: PayloadAction<{
        method: string;
        objectives: string;
      }>
    ) => {
      state.project.method = action.payload.method;
      state.project.objectives = action.payload.objectives;
    },
    updateProjectApprovedProcedure: (state, action: PayloadAction<boolean>) => {
      state.project.isApprovedProcedure = action.payload;
    },
    clearCopyOfApprovedProcedure: (state) => {
      state.project.copyOfApprovedProcedure = undefined;
    },
    updateProjectStatus: (state, action: PayloadAction<ProjectStatus>) => {
      state.project.status = action.payload;
    },
    updateCertificateNumber: (state, action: PayloadAction<string>) => {
      state.project.certificateNumber = action.payload;
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.project.name = action.payload;
      if (state.newProject) {
        state.project.fileName = action.payload;
      }
    },
    updateExecutorsList: (state, action: PayloadAction<string[]>) => {
      state.project.executorsList = action.payload;
    },
    updateSupervisorsList: (state, action: PayloadAction<string[]>) => {
      state.project.supervisorsList = action.payload;
    },
    setEditMethod: (state, action: PayloadAction<boolean>) => {
      state.editMethod = action.payload;
    },
    setTagNumbersUpdated: (state, action: PayloadAction<boolean>) => {
      state.tagNumbersUpdated = action.payload;
    },
    setHasEmptyTagNumbers: (
      state,
      action: PayloadAction<{
        allTags: boolean;
        processTags: boolean;
        electricTags: boolean;
      }>
    ) => {
      state.hasEmptyTagNumbers = action.payload.allTags;
      state.hasEnteredProcessTagNumbers = action.payload.processTags;
      state.hasEnteredElectricTagNumbers = action.payload.electricTags;
    },
    addOrRemoveCheckedValve: (state, action: PayloadAction<string>) => {
      if (state.project.checkedValves !== undefined) {
        const currentValves = [...state.project.checkedValves];
        const newElement = !currentValves.includes(action.payload);
        if (newElement) {
          state.project.checkedValves = [
            ...state.project.checkedValves,
            action.payload,
          ];
        } else {
          const adjustedValves = currentValves.filter(
            (value) => value !== action.payload
          );
          state.project.checkedValves = adjustedValves;
        }
      } else {
        state.project.checkedValves = [action.payload];
      }
    },
    addNewReviewComment: (
      state,
      action: PayloadAction<{
        comment: Comment;
      }>
    ) => {
      state.reviewComments = [...state.reviewComments, action.payload.comment];
    },
    addConnectedPnids: (state, action: PayloadAction<PnId[]>) => {
      state.connectedPnids = action.payload;
    },
    addNewPreWorkActionItem: (state, action: PayloadAction<PreWorkItem>) => {
      state.preWorkItems = [...state.preWorkItems, action.payload];
    },
    removePreWorkActionItem: (state, action: PayloadAction<PreWorkItem>) => {
      const currentPreWorkItems = [...state.preWorkItems];
      const newPreWorkItemsArray = currentPreWorkItems.map((item) =>
        item.id === action.payload.id ? { ...item, removed: true } : item
      );
      state.preWorkItems = newPreWorkItemsArray;
    },
    checkPreWorkActionItem: (state, action: PayloadAction<PreWorkItem>) => {
      const currentPreWorkItems = [...state.preWorkItems];
      const requiredCheckedState = !action.payload.checked;
      const newPreWorkItemsArray = currentPreWorkItems.map((item) =>
        item.id === action.payload.id
          ? { ...item, checked: requiredCheckedState }
          : item
      );
      state.preWorkItems = newPreWorkItemsArray;
    },
    addNewAttachment: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      state.attachmentsList = [...state.attachmentsList, action.payload];
    },
    removeFailedAttachment: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      const currentAttachments = [...state.attachmentsList];
      const newAttachmentsArray = currentAttachments.filter(
        (item) => item.id !== action.payload.id
      );
      state.attachmentsList = newAttachmentsArray;
    },
    setAttachmentToUploaded: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      const currentAttachments = [...state.attachmentsList];
      const newAttachmentsArray = currentAttachments.map((item) =>
        item.id === action.payload.id ? { ...item, uploading: false } : item
      );
      state.attachmentsList = newAttachmentsArray;
    },
    addNewApprovalFile: (
      state,
      action: PayloadAction<ProcedureApprovalFile>
    ) => {
      state.approvalFilesList = [...state.approvalFilesList, action.payload];
    },
    setApprovalFileToUploaded: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      const currentApprovalFiles = [...state.approvalFilesList];
      const newApprovalFilesArray = currentApprovalFiles.map((item) =>
        item.id === action.payload.id ? { ...item, uploading: false } : item
      );
      state.approvalFilesList = newApprovalFilesArray;
    },
    removeFailedApprovalFile: (
      state,
      action: PayloadAction<ProcedureApprovalFile>
    ) => {
      const currentApprovalFiles = [...state.approvalFilesList];
      const newApprovalFilesArray = currentApprovalFiles.filter(
        (item) => item.id !== action.payload.id
      );
      state.approvalFilesList = newApprovalFilesArray;
    },
    updateApprovalFileRemovedStatus: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      const requiredStatus = !action.payload.removed;
      const currentApprovalFiles = [...state.approvalFilesList];
      const newApprovalFilesArray = currentApprovalFiles.map((item) =>
        item.id === action.payload.id
          ? { ...item, removed: requiredStatus }
          : item
      );
      state.approvalFilesList = newApprovalFilesArray;
    },
    setPnidDrawing: (
      state,
      action: PayloadAction<{ pnid: string; drawingId: string }>
    ) => {
      return projectStateService.setPnidDrawing(
        state,
        action.payload.pnid,
        action.payload.drawingId
      );
    },
    setPnidDrawings: (
      state,
      action: PayloadAction<{ pnIdsDrawings: KeyValuePair }>
    ) => {
      const { pnIdsDrawings } = action.payload;
      state.project = {
        ...state.project,
        pnIdsDrawings,
      };
    },
    updateAttachmentRemovedStatus: (
      state,
      action: PayloadAction<ProcedureFileAttachment>
    ) => {
      const requiredStatus = !action.payload.removed;
      const currentAttachments = [...state.attachmentsList];
      const newAttachmentsArray = currentAttachments.map((item) =>
        item.id === action.payload.id
          ? { ...item, removed: requiredStatus }
          : item
      );
      state.attachmentsList = newAttachmentsArray;
    },
    clearProject: (state) => {
      state.project = {
        fileName: '',
      };
    },
    updateApprovalFileMetaData: (
      state,
      action: PayloadAction<ProcedureApprovalFile>
    ) => {
      const currentApprovalFiles = [...state.approvalFilesList];
      const newApprovalFilesArray = currentApprovalFiles.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              description: action.payload.description,
              approvedBy: action.payload.approvedBy,
            }
          : item
      );
      state.approvalFilesList = newApprovalFilesArray;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProject.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.status = Status.success;
      state.project = { ...action.payload };
    });
    builder.addCase(fetchProject.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchWorkorders.pending, (state, _action) => {
      state.woStatus = Status.loading;
    });
    builder.addCase(fetchWorkorders.fulfilled, (state, action) => {
      state.woStatus = Status.success;
      state.workorders = action.payload;
    });
    builder.addCase(fetchWorkorders.rejected, (state, action) => {
      state.woStatus = Status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchPassValves.pending, (state, _action) => {
      state.pvStatus = Status.loading;
    });
    builder.addCase(fetchPassValves.fulfilled, (state, action) => {
      state.pvStatus = Status.success;
      state.passValves = action.payload;
    });
    builder.addCase(fetchPassValves.rejected, (state, action) => {
      state.pvStatus = Status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchReviewComments.pending, (state, _action) => {
      state.commentStatus = Status.loading;
    });
    builder.addCase(fetchReviewComments.fulfilled, (state, action) => {
      state.commentStatus = Status.success;
      state.reviewComments = action.payload;
    });
    builder.addCase(fetchReviewComments.rejected, (state, action) => {
      state.commentStatus = Status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchPreWorkItems.pending, (state, _action) => {
      state.preWorkItemsStatus = Status.loading;
    });
    builder.addCase(
      fetchPreWorkItems.fulfilled,
      (state, action: PayloadAction<PreWorkItem[]>) => {
        state.preWorkItemsStatus = Status.success;
        state.preWorkItems = action.payload;
      }
    );
    builder.addCase(fetchPreWorkItems.rejected, (state) => {
      state.preWorkItemsStatus = Status.failed;
    });

    builder.addCase(fetchAttachments.pending, (state, _action) => {
      state.attachmentStatus = Status.loading;
    });
    builder.addCase(fetchAttachments.fulfilled, (state, action) => {
      state.attachmentStatus = Status.success;
      state.attachmentsList = action.payload;
    });
    builder.addCase(fetchAttachments.rejected, (state, action) => {
      state.attachmentStatus = Status.failed;
      state.error = action.error.message;
    });

    builder.addCase(fetchApprovalFiles.pending, (state, _action) => {
      state.approvalFilesStatus = Status.loading;
    });
    builder.addCase(fetchApprovalFiles.fulfilled, (state, action) => {
      state.approvalFilesStatus = Status.success;
      state.approvalFilesList = action.payload;
    });
    builder.addCase(fetchApprovalFiles.rejected, (state, action) => {
      state.approvalFilesStatus = Status.failed;
      state.error = action.error.message;
    });
  },
});

export type ProjectState = ReturnType<typeof projectSlice.reducer>;
export const { actions } = projectSlice;
export default projectSlice;
