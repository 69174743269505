/* eslint-disable lodash/prefer-some */
/* eslint-disable no-param-reassign */
import { FileInfo } from '@cognite/sdk';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'redux/types';
import { filesResourceService } from 'resources';
import { uniqBy } from 'utils/collection';

export const fetchPnIDsforAsset = createAsyncThunk(
  'files/setPnIDsforAsset',
  async (assetId: number) => {
    const allPnIDs = filesResourceService.listPnIDsforAsset(assetId);
    return allPnIDs;
  }
);
export const fetchActiveFileById = createAsyncThunk(
  'files/fetchActiveFileById',
  async (id: number) => {
    const file = await filesResourceService.fetchFileById(id);
    return file;
  }
);

export const fetchFilesByIds = createAsyncThunk(
  'files/fetchFilesById',
  async (ids: number[]) => {
    const files = filesResourceService.fetchFilesByIds(ids);
    return files;
  }
);

export const loadProcedureFilesByIds = createAsyncThunk(
  'files/loadProcedureFilesByIds',
  async (ids: number[]) => {
    if (!ids || !ids.length) {
      return [];
    }
    const files = filesResourceService.fetchFilesByIds(ids);
    return files;
  }
);

export const searchFilesByAssetIds = createAsyncThunk(
  'files/searchFilesByAssetIds',
  async (assetIds: number[] | undefined) => {
    if (!assetIds) {
      return [];
    }
    const files = filesResourceService.searchFilesByAssetIds(assetIds);
    return files;
  }
);

export const searchFilesByFileIds = createAsyncThunk(
  'files/searchFilesByFilesIds',
  async (ids: number[]) => {
    const files = filesResourceService.fetchFilesByIds(ids);
    return files;
  }
);

export type FileInfoWithConnectedFiles = FileInfo & {
  connectedFiles: number[];
};

const filesSlice = createSlice({
  name: 'files',
  initialState: {
    files: [] as Array<FileInfo>,
    search: [] as Array<FileInfo>,
    activeFile: {} as FileInfoWithConnectedFiles,
    connectedFiles: [] as number[],
    status: Status.idle,
    error: undefined as string | undefined,
  },
  reducers: {
    connectFile: (state, action) => {
      const connectedFileId = action.payload;
      if (
        connectedFileId &&
        state.connectedFiles &&
        !state.connectedFiles.includes(connectedFileId)
      ) {
        state.connectedFiles.push(connectedFileId);
      }
    },
    setActiveFile: (state, action) => {
      const activeFile = state.files.find(
        (file) => file.id === action.payload
      ) as FileInfo;
      state.activeFile = {
        ...activeFile,
        connectedFiles: state.activeFile.connectedFiles,
      };
    },
    setStartingFile: (state, action) => {
      const fileId = action.payload;
      const activeFile = state.files.find(
        (file) => file.id === fileId
      ) as FileInfo;

      state.activeFile = {
        ...activeFile,
        connectedFiles: state.connectedFiles.filter(
          (curFileId) => curFileId === fileId
        ),
      };
    },
    disconnectFile: (state, action) => {
      if (state.connectedFiles && action.payload) {
        state.connectedFiles = state.connectedFiles.filter(
          (fileId) => fileId !== action.payload
        );
        state.files = state.files.filter((file) => file.id !== action.payload);
      }
    },
    removeUnusedFiles: (state, action: PayloadAction<number[]>) => {
      // pass the files you want to keep here, not the ones you want to remove
      if (state.connectedFiles && action.payload.length) {
        state.connectedFiles = state.connectedFiles.filter((fileId) =>
          action.payload.includes(fileId)
        );
        state.files = state.files.filter((file) =>
          action.payload.includes(file.id)
        );
      }
    },
    clearConnectedFiles: (state, action) => {
      const originalFileId = action.payload;
      if (originalFileId) state.connectedFiles = [action.payload];
      else state.connectedFiles = [];
    },
    clearFiles: (state) => {
      state.files = [];
      state.search = [];
      state.activeFile = {} as FileInfoWithConnectedFiles;
      state.connectedFiles = [];
      state.status = Status.idle;
      state.error = undefined as string | undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPnIDsforAsset.fulfilled,
      (state, action: PayloadAction<FileInfo[]>) => {
        const allFiles = [...action.payload];
        let uniqueFiles = allFiles.length ? [...allFiles] : [];
        uniqueFiles = uniqBy(
          uniqueFiles.sort((a, b) => {
            return b.createdTime.getTime() > a.createdTime.getTime() ? 1 : -1;
          }),
          'name'
        );
        state.files = uniqueFiles;
        state.status = Status.success;
      }
    );
    builder.addCase(fetchPnIDsforAsset.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = Status.failed;
    });
    builder.addCase(fetchPnIDsforAsset.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(fetchActiveFileById.fulfilled, (state, action) => {
      state.activeFile = {
        ...action.payload,
        connectedFiles: state.activeFile.connectedFiles,
      };
      if (
        state.files.findIndex((file) => file.id === action.payload.id) === -1
      ) {
        state.files.push(action.payload);
      }
      state.status = Status.success;
    });
    builder.addCase(fetchActiveFileById.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(fetchActiveFileById.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(fetchFilesByIds.fulfilled, (state, action) => {
      state.files = action.payload;
    });
    builder.addCase(fetchFilesByIds.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(fetchFilesByIds.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(searchFilesByAssetIds.fulfilled, (state, action) => {
      state.search = action.payload;
      state.status = Status.success;
    });
    builder.addCase(searchFilesByAssetIds.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(searchFilesByAssetIds.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(searchFilesByFileIds.fulfilled, (state, action) => {
      state.search = action.payload;
      state.status = Status.success;
    });
    builder.addCase(searchFilesByFileIds.rejected, (state, action) => {
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(searchFilesByFileIds.pending, (state, _action) => {
      state.status = Status.loading;
    });
    builder.addCase(loadProcedureFilesByIds.fulfilled, (state, action) => {
      const files = action.payload;
      state.files = files;
      state.search = [];
      state.status = Status.success;
      const connectedFilesIds = files.length
        ? files.map((file) => file.id)
        : [];
      state.connectedFiles = connectedFilesIds;
    });
    builder.addCase(loadProcedureFilesByIds.rejected, (state, action) => {
      state.files = [];
      state.search = [];
      state.connectedFiles = [];
      state.status = Status.failed;
      state.error = action.error.message;
    });
    builder.addCase(loadProcedureFilesByIds.pending, (state, _action) => {
      state.status = Status.loading;
    });
  },
});

export type FilesState = ReturnType<typeof filesSlice.reducer>;
export const { actions } = filesSlice;
export default filesSlice;
