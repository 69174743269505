// temporary eslint disable
/* eslint-disable */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Status } from 'redux/types';
import { useHotSpot } from 'redux/reducers/hotSpot/hooks';
import { actions, FilesState } from 'redux/reducers/files';
import { useWorkSteps } from 'redux/reducers/worksteps';
import { useAnnotations } from 'redux/reducers/annotations';
import { ProjectState } from 'redux/reducers/project';
import { useProcedureFilesLoad } from 'hooks/useProcedureFilesLoad';
import { Flex, toast, Icon, Button } from '@cognite/cogs.js';
import { KeyValuePair, WorkStep, CogniteAnnotation, Phase } from 'types';
import { cookieServices, workstepsService } from 'services';
import { useDrawings } from 'hooks/useDrawings';
import debounce from 'lodash/debounce';
import {
  Annotation,
  TooltipAnchorPosition,
  ContainerConfig,
  ContainerType,
  ToolType,
  IdsByType,
  UnifiedViewerNode,
} from '@cognite/unified-file-viewer';
import { AnnotationModel, FileLink, IdEither } from '@cognite/sdk';
import { useColor } from 'react-color-palette';
import { v4 as uuidv4 } from 'uuid';
import { AnnotationForm } from '../AnnotationForm';
import { StepBox } from './StepBox';
import SideDrawer from './SideDrawer';
import { showFile } from './ShowFile';
import {
  AnnotationFormWrapper,
  FilePreviewWrapper,
  FileRefreshButton,
  Loader,
  LoaderWrapper,
  FileQualityButtons,
} from './elements';
import { useSideDrawerFilesActions } from './hooks/useSideDrawerFilesActions';
import { usePnidZoom, ViewerQuality } from './hooks/usePnidZoom';
import { PnidMiniWidget } from './PnidMiniWidget';
import { getClient } from '../../../../config/cognitesdk';
import { HotSpotMenu } from '../HotSpotMenu/HotSpotMenu';
import useManagedTools from '../../../../components/UnifiedFileViewer/hooks/useManagedTools';
import UnifiedFileViewer from '../../../../components/UnifiedFileViewer/UnifiedFileViewer';
import { ToolBarSettings } from '../../../../components/UnifiedFileViewer/toolbarSettings';
import PolylineToolBar from 'components/UnifiedFileViewer/components/PolylineToolBar/PolylineToolBar';
import { ToolConfig } from '@cognite/unified-file-viewer/dist/core/tools/types';
import { actions as annotationActions } from 'redux/reducers/annotations/annotationsReducer';
import { rgbToRgba } from 'utils/colors';
import { ReactUnifiedViewerProps } from '@cognite/unified-file-viewer/dist/core/react/types';
// import { AuthState } from 'redux/reducers/auth';

type PnidPreviewProps = {
  fileIds: number[];
  newProject: boolean;
  annotations?: any[];
  workStepsFileIds: number[];
  setWorkStepsFileIds: Function;
  viewOnly: boolean;
  showWorkSteps?: boolean;
};

type UpdateHandlerFn = Exclude<
  ReactUnifiedViewerProps['onUpdateRequest'],
  undefined
>;

export const FilePreview = (props: PnidPreviewProps) => {
  const { fileIds, viewOnly, showWorkSteps } = props;
  const dispatch = useDispatch();
  // const metrics = useMetrics(METRICS_AREA.PNID_LOAD);
  const { selectHotSpot, activeHotSpot, clearActiveHotSpot } = useHotSpot();
  const { project, newProject } = useSelector<RootState, ProjectState>(
    (state: RootState) => state.project
  );
  // const {user} = useSelector<RootState, AuthState>(
  //   (state: RootState) => state.auth
  // );

  const {
    active,
    workStepsLoaded,
    phases,
    reorderedWorkStepPosition,
    selectWorkStep,
    workStepsContainingAnnotation,
    getFirstPnidId,
    countAllWorkSteps,
    fetchFileForActiveWorkstep,
    cancelActive,
    setWorkStepAnnotationCustomOffset,
    getWorkstepPairs,
  } = useWorkSteps();

  let {
    file,
    fileId,
    files,
    connectedFiles,
    fetchFile,
    setFileId,
    onAddFile,
    onFileRemoved,
    onFileSelected,
    chooseStartingFile,
  } = useSideDrawerFilesActions();

  const [isReady, setIsReady] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { preloadFilesWithAnnotations } = useProcedureFilesLoad();
  const {
    fetchDrawingForPnid,
    saveDrawingForPnid,
    getEmptyDrawingContent,
    preloadDrawingsForProcedure,
  } = useDrawings();
  const [showLoader, setShowLoader] = useState(false);
  const [pnidLoadStartTime, setPnidLoadStartTime] = useState(0);
  const [drawing, setDrawing] = useState<string | undefined>(undefined);

  const isExecutionView = project.status === 'execution';

  const quality = cookieServices.getFilePreviewQuality() || ViewerQuality.HIGH;
  const fileViewerQuality = useMemo(() => {
    // Returning undefined will make scale depending on the screen size
    if (!isExecutionView) {
      // Always show the highest quality for editing procedure
      return undefined;
    }
    if (quality === ViewerQuality.LOW) {
      return 1;
    }
    if (quality === ViewerQuality.MEDIUM) {
      return 3;
    }
    return undefined;
  }, [quality, isExecutionView]);

  const {
    editMode,
    newAnnotation,
    fileColors,
    fetchAnnotations,
    createNewAnnotation,
    highlightedAnnotations,
    setAnnotationCustomOffset,
  } = useAnnotations();

  const {
    tool,
    setTool,
    toolConfigByToolType: optionsByToolType,
  } = useManagedTools(ToolType.SELECT);

  const { curZoomedAnnotation, zoomOnAnnotation, setZoomedAnnotation } =
    usePnidZoom(quality);

  const numberOfFiles = Number(fileIds?.length);
  const noWorksteps = countAllWorkSteps() === 0;

  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [annotationData, setAnnotationData] = useState<Annotation[]>([]);
  const [activeAnnotation, setActiveAnnotation] = useState<AnnotationModel>();
  const [clickedId, setClickedId] = useState<number>(0);
  const [polylineColor, setPolylineColor] = useColor('#96df96');
  const [polylineSize, setPolylineSize] = useState(12);
  let [copyoptionsByToolType, setCopyoptionsByToolType] = useState<ToolConfig>(
    {} as ToolConfig
  );
  const toolbarRef = useRef<HTMLDivElement>(null);

  const annotationSet = useSelector<RootState>(
    (state: RootState) => state.annotations.annotations[file.id]
  ) as Annotation[];

  const [urls, setUrls] = useState<(FileLink & IdEither)[] | undefined>();
  const [polylineNodes, setPolylineNodes] = useState<UnifiedViewerNode[]>([]);
  const [container, setContainer] = useState<ContainerConfig[]>([]);
  const client = getClient();

  useEffect(() => {
    fetchDrawing(file.id);
  }, [file.id]);

  useEffect(() => {
    if (drawing) {
      const ufvNodes = FlatDrawingToUFVNode(drawing);
      if (ufvNodes.length > 0) {
        setPolylineNodes(ufvNodes);
      }
    }
  }, [drawing, file.id]);

  useEffect(() => {
    if (tool.type === 'polyline') {
      const obj = {
        type: ToolType.POLYLINE,
        fill: `${polylineColor.hex}`,
        stroke: `${polylineColor.hex}`,
        strokeWidth: polylineSize,
        opacity: 0.6,
      };
      setCopyoptionsByToolType(obj as ToolConfig);
    }
  }, [polylineColor, polylineSize, tool.type]);

  useEffect(() => {
    const updatedAnnotations = annotations.map((annotation) => ({
      ...annotation,
      onClick: (e: any, currentAnnotation: Annotation) => {
        if (active) {
          // setClickedId(annotation.id); TODO: trial and remove
          e.cancelBubble = true;
        }
      },
    }));

    setAnnotationData(updatedAnnotations);
  }, [annotations]);

  useEffect(() => {
    if (activeHotSpot.annotation) {
      const annotation = activeHotSpot.annotation;
      setClickedId(annotation.id);
      setActiveAnnotation(annotation);
    }
  }, [activeHotSpot]);

  useEffect(() => {
    if (fileId !== undefined) {
      const annotations = highlightedAnnotations(file.id);
      if (annotations) {
        // setting to annotations type to 'any' temporarily
        const textRegions = annotations.map((annotation: any) => {
          if (annotation.data.textRegion) {
            return {
              id: String(annotation.id),
              containerId: String(fileId),
              type: 'rectangleAnnotation',
              x: annotation.data.textRegion.xMin,
              y: annotation.data.textRegion.yMin,
              width:
                annotation.data.textRegion.xMax -
                annotation.data.textRegion.xMin,
              height:
                annotation.data.textRegion.yMax -
                annotation.data.textRegion.yMin,
              style: {
                ...(annotation.mark &&
                  'strokeColor' in annotation.mark && {
                    stroke: checkIfOldAnnotation(annotation, active)
                      ? annotation.mark.strokeColor
                      : rgbToRgba(annotation.mark.strokeColor as string, 0.3),
                  }),
                ...(annotation.mark &&
                  'strokeWidth' in annotation.mark && {
                    strokeWidth: annotation.mark.strokeWidth,
                  }),
                fill: rgbToRgba(annotation.mark.backgroundColor as string, 0.3),
              },
              metadata: {
                annotation,
              },
              isDraggable: false,
              isResizable: false,
            } as Annotation;
          }
        });
        setAnnotations(textRegions as Annotation[]);
      }
    }
  }, [fileId, file, annotationSet, active]);

  const checkIfOldAnnotation = (annotation: any, active: any) => {
    const isOldAnnotation =
      'oldAnnotationId' in annotation?.data &&
      annotation?.data.oldAnnotationId &&
      annotation?.creatingUser !== 'currentLoginUser';
    const isStepItemAvailable = active.workStep && active.workStep.stepItem;

    if (isOldAnnotation && isStepItemAvailable) {
      return (
        active.workStep.stepItem.annotation?.id ===
          parseInt(annotation?.data.oldAnnotationId, 10) ||
        [
          active.workStep?.stepItem?.relativeRef?.annotation?.id,
          active.workStep?.stepItem?.line?.annotation?.id,
        ].includes(parseInt(annotation?.data.oldAnnotationId, 10))
      );
    } else if (isStepItemAvailable) {
      return (
        active.workStep.stepItem.annotation?.id === annotation.id ||
        [
          active.workStep?.stepItem?.relativeRef?.annotation?.id,
          active.workStep?.stepItem?.line?.annotation?.id,
        ].includes(annotation.id)
      );
    }
  };

  let urlsResponse: (FileLink & IdEither)[];
  useEffect(() => {
    const fetchUrls = async () => {
      if (fileId) {
        urlsResponse = await client.files.getDownloadUrls([{ id: fileId }]);
      }
      setUrls((prevURls) => {
        if (prevURls !== urlsResponse) return urlsResponse;
      });
    };

    fetchUrls();
  }, [fileId]);

  const CONTAINERS: ContainerConfig[] | any = urls
    ?.filter((url) => 'id' in url)
    .map((url) => ({
      id: String(fileId),
      type: ContainerType.DOCUMENT,
      url: url.downloadUrl.toString(),
      maxWidth: 5000,
      maxHeight: 5000,
      isDraggable: true,
    }));

  const fetchDrawing = useCallback(
    (fileIdNumber: number) => {
      fetchDrawingForPnid(
        fileIdNumber,
        project.id as string,
        project.pnIdsDrawings as KeyValuePair
      )
        .then((drawingContent) => {
          setDrawing(drawingContent);
        })
        .catch(() => setDrawing(getEmptyDrawingContent()));
    },
    [
      fetchDrawingForPnid,
      getEmptyDrawingContent,
      project.id,
      project.pnIdsDrawings,
    ]
  );

  const { status: statusFiles } = useSelector<RootState, FilesState>(
    (state) => state.files
  );
  const statusAnnotations = useSelector<RootState>(
    (state: RootState) => state.annotations.status
  ) as Status;
  const statusProcedureAnnotations = useSelector<RootState>(
    (state: RootState) => state.annotations.annotationsLoadedStatus
  ) as Status;

  const isFileFullyLoaded =
    statusFiles === Status.success &&
    statusAnnotations === Status.success &&
    statusProcedureAnnotations === Status.success &&
    isReady;

  // show sidebar if no worksteps and have files
  useEffect(() => {
    if (numberOfFiles > 1 && workStepsLoaded && isReady && noWorksteps) {
      setIsDrawerOpen(true);
    }
    // eslint-disable-next-line
  }, [numberOfFiles, workStepsLoaded, isReady, noWorksteps]);

  // Listen for file change and set the local fileId vairable
  // Internally the fileId is used to detect changes
  useEffect(() => {
    if (file.id !== fileId) {
      setFileId(file.id);
    }
  }, [file.id, fileId, setFileId]);

  // When the active workstep is changed
  // zoom the P&ID to the active ws
  useEffect(() => {
    if (!showLoader && active.workStep && project.status !== 'compilation') {
      zoomOnAnnotation(active);
    }
    // eslint-disable-next-line
  }, [active, showLoader, zoomOnAnnotation]);

  // Init hook, first load, it preloads the P&IDs, annotations...etc.
  useEffect(() => {
    if (
      workStepsLoaded &&
      statusProcedureAnnotations === Status.idle &&
      !isReady
    ) {
      setDrawing(getEmptyDrawingContent());
      preloadFilesWithAnnotations(project, phases, newProject, fileIds);
      preloadDrawingsForProcedure(project);
    }
    // eslint-disable-next-line
  }, [
    isReady,
    workStepsLoaded,
    statusProcedureAnnotations,
    props.newProject,
    phases,
    project,
    preloadFilesWithAnnotations,
    preloadDrawingsForProcedure,
    getEmptyDrawingContent,
  ]);

  // After preload is done, it selects the first P&ID from ws
  useEffect(() => {
    if (
      workStepsLoaded &&
      statusProcedureAnnotations === Status.success &&
      !isReady
    ) {
      // Read out if user has been working on different p&id and reloads
      const cookieInfo = cookieServices.getFilePreviewInfo();
      if (cookieInfo) {
        if (!fileIds.includes(cookieInfo.id)) {
          dispatch(actions.connectFile(cookieInfo.id));
          onAddFile(cookieInfo);
        } else {
          dispatch(actions.setActiveFile(cookieInfo.id));
          setFileId(cookieInfo.id);
        }
        // Remove cookie after reload so user gets initial p&id on next visit
        cookieServices.removeFilePreviewInfo();
      } else {
        const pnidFromWorsteps = getFirstPnidId();
        if (pnidFromWorsteps) {
          dispatch(actions.setActiveFile(pnidFromWorsteps));
        } else if (!pnidFromWorsteps && numberOfFiles === 1) {
          setFileId(fileIds[0]);
          dispatch(actions.setActiveFile(fileIds[0]));
        }
      }

      setIsReady(true);
    }
  }, [
    dispatch,
    isReady,
    workStepsLoaded,
    statusProcedureAnnotations,
    props.newProject,
    numberOfFiles,
    fileIds,
    setFileId,
    fetchDrawing,
    onAddFile,
  ]);

  // Watch the active ws, when it is changed, the new file is loaded
  useEffect(() => {
    if (
      active.workStep?.id &&
      active.workStep.stepItem &&
      active.workStep.stepItem.annotation
    ) {
      const workStepFileId =
        active.workStep.stepItem?.annotation?.annotatedResourceId;
      if (fileId !== workStepFileId) {
        // commented temporarily
        // setShowLoader(true);
        setDrawing(getEmptyDrawingContent());

        const timeoutInMsToRedrawUI = project.status !== 'execution' ? 800 : 0;
        setPnidLoadStartTime(window.performance.now());
        // timeout is set to wait firt ui actions to finish
        // to avoid poor rendering performance
        // triggering setActiveFile will trigger other events
        // in order file to be fetched with all other things
        setTimeout(() => {
          fetchFileForActiveWorkstep(active.workStep as WorkStep);
        }, timeoutInMsToRedrawUI);
      }
    }

    // eslint-disable-next-line
  }, [active.workStep]);

  useEffect(() => {
    if (statusProcedureAnnotations === Status.failed) {
      toast.error(
        <div>
          Annotations for this procedure can not be fetched. Please try to
          refresh the page.
        </div>
      );
    }

    // eslint-disable-next-line
  }, [statusProcedureAnnotations]);

  useEffect(() => {
    const connectedWorkstepsFileIds =
      workstepsService.getWorkstepsFileIds(phases);
    if (connectedWorkstepsFileIds.length !== props.workStepsFileIds.length) {
      props.setWorkStepsFileIds(connectedWorkstepsFileIds);
    }

    // eslint-disable-next-line
  }, [connectedFiles, phases]);

  const onArrowBoxMove = useCallback(
    (arrowBox: any) => {
      setAnnotationCustomOffset(
        file.id,
        +arrowBox.annotationId,
        arrowBox.offsetX as number,
        arrowBox.offsetY as number
      );
      setWorkStepAnnotationCustomOffset(
        +arrowBox.annotationId,
        arrowBox.offsetX as number,
        arrowBox.offsetY as number
      );
    },
    [file.id, setAnnotationCustomOffset, setWorkStepAnnotationCustomOffset]
  );

  const onDrawingSaved = useCallback(
    (newDrawData: string) => {
      if (
        newDrawData &&
        newDrawData.length > 0 &&
        drawing !== String(newDrawData)
      ) {
        const projectId = project.id || 'undefined';
        saveDrawingForPnid(file.id, projectId, String(newDrawData));
      }
    },
    [drawing, file.id, project.id, saveDrawingForPnid]
  );

  const onAnnotationSelected = debounce((ids: IdsByType) => {
    const selectedAnnotationId = ids.annotationIds[0];
    const selectedAnnotation = annotationData.filter((annotation) => {
      if ('metadata' in annotation && 'annotation' in annotation.metadata)
        return (
          annotation.metadata.annotation.id === parseInt(selectedAnnotationId)
        );
    });
    if (selectedAnnotation.length > 0 && 'metadata' in selectedAnnotation[0]) {
      const annotation = selectedAnnotation[0].metadata?.annotation;
      const isFile = annotation.data.linkedResourceType === 'file';
      const connectedFileId = annotation?.data.linkedResourceInternalId;
      if (isFile && annotation?.data.linkedResourceInternalId) {
        // setShowLoader(true); TODO: temporary comment. Might be removed later
        setZoomedAnnotation(undefined);
        cancelActive();
        if (connectedFiles.includes(connectedFileId)) {
          dispatch(actions.setActiveFile(connectedFileId));
        } else {
          dispatch(
            actions.connectFile(annotation?.data.linkedResourceInternalId)
          );
          fetchFile(connectedFileId);
          const needsMigration =
            project.needsMigration && project.needsMigration === true;
          fetchAnnotations(connectedFileId, phases, needsMigration);
        }
      } else {
        selectHotSpot(annotation);
      }
    }
  }, 200);

  const isOldAnnotation = (annotation: Annotation): string => {
    if (
      annotation.metadata &&
      'annotation' in annotation.metadata &&
      'data' in annotation.metadata.annotation &&
      'oldAnnotationId' in annotation.metadata.annotation.data &&
      annotation?.metadata?.annotation?.data?.oldAnnotationId
    ) {
      if (
        annotation?.metadata?.annotation?.creatingUser === 'currentLoginUser'
      ) {
        return annotation.id;
      } else if (
        checkWorkstepWithAnnotationId(phases, parseInt(annotation.id))
      ) {
        return annotation.id;
      }
      return annotation.metadata.annotation.data.oldAnnotationId;
    } else {
      return annotation.id;
    }
  };

  const checkWorkstepWithAnnotationId = (
    phases: Phase[],
    targetAnnotationId: number
  ) => {
    for (const phase of phases) {
      for (const workstep of phase.workSteps) {
        if (
          workstep?.stepItem?.annotation &&
          workstep?.stepItem?.annotation?.id === targetAnnotationId
        ) {
          return true;
        }
      }
    }
  };

  const renderStepBox = (annotation: any) => {
    const workSteps = workStepsContainingAnnotation(
      isOldAnnotation(annotation),
      true
    );

    if (workSteps.length) {
      const workstepPairs = getWorkstepPairs(workSteps);
      return (
        <StepBox
          active={active.workStep}
          workStepsPairs={workstepPairs}
          workSteps={workSteps}
          onSelected={selectWorkStep}
        />
      );
    }
    return <></>;
  };

  const renderStepBoxConditionally = (annotation: any) => {
    if (showWorkSteps) {
      if ('workStep' in active && active.workStep !== undefined) {
        if (
          annotation.metadata &&
          'annotation' in annotation.metadata &&
          'data' in annotation.metadata.annotation &&
          'oldAnnotationId' in annotation.metadata.annotation.data &&
          annotation?.metadata?.annotation?.data?.oldAnnotationId &&
          active.workStep?.stepItem?.annotation?.id ===
            parseInt(annotation?.metadata?.annotation?.data?.oldAnnotationId)
        ) {
          return renderStepBox(annotation);
        }
        if (
          active.workStep?.stepItem?.annotation?.id === parseInt(annotation.id)
        ) {
          return renderStepBox(annotation);
        }
        if (active.editable) {
          return renderStepBox(annotation);
        }
        if (
          !active.workStep?.stepItem?.annotation?.id &&
          !active.workStep?.stepItem?.relativeRef?.annotation?.id &&
          !active.workStep?.stepItem?.line?.annotation?.id
        ) {
          return renderStepBox(annotation);
        }
        return <></>;
      } else {
        return renderStepBox(annotation);
      }
    } else {
      return renderStepBox(annotation);
    }
  };

  useMemo(
    () => ({
      onCreate: (annotation: CogniteAnnotation) => {
        createNewAnnotation(annotation, file);
        return false;
      },
      onUpdate: (_annotation: CogniteAnnotation) => {
        return false;
      },
    }),
    [createNewAnnotation, file]
  );

  const setQuality = (qualityValue: ViewerQuality) => {
    cookieServices.setFilePreviewQuality(qualityValue);
    window.location.reload();
  };

  const onStageClick = (e: any) => {
    if ('workStep' in active) {
      cancelActive();
    }
    clearActiveHotSpot();
  };

  const stepBoxes = useMemo(() => {
    return annotationData.map((annotation) => {
      return {
        id: annotation.id,
        targetIds: [annotation.id],
        content: renderStepBoxConditionally(annotation),
        anchorTo: TooltipAnchorPosition.RIGHT_CENTER,
      };
    });
  }, [annotationData, active, showWorkSteps, reorderedWorkStepPosition]);

  const hotSpotMenu = useMemo(() => {
    if (clickedId === undefined) {
      return [];
    }

    if (activeAnnotation)
      return [
        {
          targetIds: [clickedId.toString()],
          content: <HotSpotMenu annotation={activeAnnotation} />,
          anchorTo: TooltipAnchorPosition.TOP_LEFT,
        },
      ];
  }, [clickedId, activeAnnotation]);

  const toolTipItems = [...stepBoxes, ...(hotSpotMenu || [])];

  const undoLastPolyline = () => {
    if (polylineNodes.length > 1) {
      const lastNode = polylineNodes[polylineNodes.length - 1];
      if (
        'vertices' in lastNode &&
        lastNode.vertices &&
        lastNode.vertices?.length > 0
      ) {
        const updatedVertices = lastNode.vertices.slice(0, -1);
        const updatedPolylineNodes = [
          ...polylineNodes.slice(0, -1),
          ...(updatedVertices.length > 1
            ? [{ ...lastNode, vertices: updatedVertices }]
            : []),
        ];
        setPolylineNodes(updatedPolylineNodes);
      }
    } else if (polylineNodes.length === 1 && 'vertices' in polylineNodes[0]) {
      const updatedVertices = polylineNodes[0].vertices?.slice(0, -1);
      const updatedPolylineNodes = [
        { ...polylineNodes[0], vertices: updatedVertices },
      ];
      setPolylineNodes(
        updatedVertices && updatedVertices?.length > 1
          ? updatedPolylineNodes
          : []
      );
    }
  };

  const eraseAllPolylines = () => {
    setPolylineNodes([]);
  };

  const UFVNodeToFlatDrawing = (annotations: UnifiedViewerNode[]) => {
    const drawingCoordinates: any[] = [];
    annotations.forEach((annotation) => {
      if ('vertices' in annotation) {
        const lineObj = {
          points: annotation.vertices,
          brushColor:
            annotation.style?.stroke !== undefined
              ? annotation.style?.stroke?.startsWith('rgba(')
                ? annotation.style?.stroke
                : rgbToRgba(
                    annotation.style?.stroke!,
                    annotation.style?.opacity!
                  )
              : 'rgba(150, 223, 150, 0.6)',
          brushRadius: annotation.style?.strokeWidth,
        };
        drawingCoordinates.push(lineObj);
      }
    });

    return drawingCoordinates;
  };

  const FlatDrawingToUFVNode = (drawing: string) => {
    const drawingParsed = JSON.parse(drawing);
    const ufvNodes: UnifiedViewerNode[] = [];

    if (CONTAINERS) {
      if ('lines' in drawingParsed) {
        drawingParsed.lines.forEach((item: any) => {
          const node = {
            id: uuidv4(),
            type: 'polylineAnnotation',
            containerId: CONTAINERS[0].id,
            isSelectable: false,
            isDraggable: false,
            isResizable: false,
            vertices: item.points,
            startEndType: 'none',
            endEndType: 'none',
            style: {
              strokeWidth:
                parseInt(item.brushRadius) > 40
                  ? 12
                  : parseInt(item.brushRadius),
              stroke: item.brushColor,
              opacity: 0.9,
            },
          } as UnifiedViewerNode;
          ufvNodes.push(node);
        });
      }
    }
    return ufvNodes;
  };

  const savePolylineAnnotations = (annotations: UnifiedViewerNode[]) => {
    const lines =
      annotations.length > 0 ? UFVNodeToFlatDrawing(annotations) : [];

    const transformedDrawing = {
      lines: lines,
      width: '100%',
      height: '100%',
    };
    onDrawingSaved(JSON.stringify(transformedDrawing));
  };

  const onUpdateAnnotations: UpdateHandlerFn = useCallback(
    ({ containers: updatedContainers, annotations: annotations }) => {
      let newAnnotations;

      if (updatedContainers.length > 0) {
        setContainer(updatedContainers);
      }

      if (annotations.length > 0 && tool.type === 'rectangle') {
        newAnnotations = annotations.map((annotation: any) => ({
          annotatedResourceType: 'file',
          annotatedResourceId: Number(annotation.containerId),
          annotationType: 'isoplan.IsoPlanAnnotation',
          creatingApp: 'isoplan',
          creatingAppVersion: '1.2.0',
          creatingUser: 'currentLoginUser',
          data: {
            textRegion: {
              xMax: annotation.x + annotation.width,
              xMin: annotation.x,
              yMin: annotation.y,
              yMax: annotation.y + annotation.height,
            },
          },
          status: 'approved',
        })) as CogniteAnnotation[];

        dispatch(
          annotationActions.setNewAnnotation({
            newAnnotation: newAnnotations[0],
          })
        );
      } else if (annotations.length > 0 && tool.type === 'polyline') {
        const polylineAnnotation = {
          ...annotations[0],
          style: {
            strokeWidth:
              annotations[0].style &&
              'strokeWidth' in annotations[0].style &&
              annotations[0].style.strokeWidth
                ? annotations[0].style.strokeWidth
                : 12,
            stroke:
              annotations[0].style &&
              'stroke' in annotations[0].style &&
              annotations[0].style.stroke
                ? annotations[0].style.stroke
                : 'rgb(150, 223, 150)',
            opacity: 0.6,
          },
        };

        setPolylineNodes((prevNodes) => [
          ...prevNodes,
          ...([polylineAnnotation] as UnifiedViewerNode[]),
        ]);
      }
    },
    [annotationSet, tool.type]
  );

  const handleFocus = () => {
    setTool(ToolType.SELECT);
  };

  // TODO: move containers to state
  const mergeContainers = (
    containerA: ContainerConfig[],
    containerB: ContainerConfig[]
  ) => {
    const mergedMap = new Map();

    [...containerA, ...containerB].forEach((obj) => {
      const existingObj = mergedMap.get(obj.id);
      if (existingObj) {
        mergedMap.set(obj.id, {
          ...existingObj,
          ...obj,
        });
      } else {
        mergedMap.set(obj.id, obj);
      }
    });

    return Array.from(mergedMap.values());
  };

  let nodesCollection = [...annotationData, ...polylineNodes];
  if (container.length > 0 && CONTAINERS && CONTAINERS.length > 0) {
    const containerMerged = mergeContainers(CONTAINERS, container);
    if (containerMerged.length > 1) {
      nodesCollection = [...nodesCollection, ...[containerMerged[0]]];
    } else {
      nodesCollection = [...nodesCollection, ...containerMerged];
    }
  } else if (CONTAINERS && CONTAINERS.length > 0) {
    nodesCollection = [...nodesCollection, ...CONTAINERS];
  }

  const FileViewer = (
    <>
      <UnifiedFileViewer
        applicationId="my-application"
        shouldUseAdaptiveRendering={true}
        nodes={nodesCollection}
        tool={
          tool.type === 'polyline' &&
          Object.keys(copyoptionsByToolType).length > 0
            ? copyoptionsByToolType
            : tool
        }
        tooltips={toolTipItems}
        onClick={onStageClick}
        cogniteClient={getClient() as any}
        shouldAllowDragDrop={true}
        onUpdateRequest={onUpdateAnnotations}
        onToolChange={setTool}
        activeTool={
          tool.type === 'polyline' &&
          Object.keys(copyoptionsByToolType).length > 0
            ? copyoptionsByToolType
            : tool
        }
        toolConfigByToolType={optionsByToolType}
        style={
          project.status === 'execution'
            ? {
                position: 'absolute',
                transform: 'translateY(-50%)',
                top: '50%',
                left: 0,
              }
            : { position: 'absolute', bottom: 50, left: 25 }
        }
        tools={
          project.status === 'execution'
            ? ToolBarSettings.filter(
                (tool) =>
                  tool.type === ToolType.PAN || tool.type === ToolType.SELECT
              )
            : ToolBarSettings.filter((tool) => tool.type !== ToolType.PAN)
        }
        onSelect={onAnnotationSelected}
        innerRef={toolbarRef}
      />
    </>
  );

  const filePreview = (
    <>
      {showFile({
        fileId,
        fileIds,
        isFileFullyLoaded,
        FileViewer,
      })}
      {newAnnotation?.annotation &&
        'textRegion' in newAnnotation.annotation.data && (
          <AnnotationFormWrapper>
            <AnnotationForm
              annotation={newAnnotation?.annotation}
              oldAnnotation={newAnnotation?.oldAnnotation}
              procedureNeedsMigration={project.needsMigration}
              onFocus={handleFocus}
            />
          </AnnotationFormWrapper>
        )}
      {tool.type === 'polyline' && (
        <PolylineToolBar
          color={polylineColor}
          polylineSize={polylineSize}
          polylineNodes={polylineNodes}
          setPolylineSize={setPolylineSize}
          setColor={setPolylineColor}
          eraseAllPolylines={eraseAllPolylines}
          undoLastPolyline={undoLastPolyline}
          savePolylineAnnotations={savePolylineAnnotations}
        />
      )}
    </>
  );

  return (
    <FilePreviewWrapper
      data-testid="pnidPreview"
      data-activefileid={fileId || ''}
    >
      <LoaderWrapper
        data-testid="pnid-main-loader"
        className={showLoader ? 'visible' : ''}
      >
        <Loader />
      </LoaderWrapper>
      {isExecutionView ? (
        <>
          <FileRefreshButton
            type="secondary"
            onClick={() => {
              cookieServices.setFilePreviewInfo(file);
              window.location.reload();
            }}
          >
            <Icon
              type="Warning"
              style={{
                marginRight: '10px',
              }}
            />
            Reload
          </FileRefreshButton>
          <FileQualityButtons>
            <Button
              type={quality === ViewerQuality.LOW ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.LOW)}
            >
              Low
            </Button>
            <Button
              type={quality === ViewerQuality.MEDIUM ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.MEDIUM)}
            >
              Med
            </Button>
            <Button
              type={quality === ViewerQuality.HIGH ? 'primary' : 'secondary'}
              onClick={() => setQuality(ViewerQuality.HIGH)}
            >
              High
            </Button>
          </FileQualityButtons>
        </>
      ) : null}
      {urls ? filePreview : null}
      {project.status !== 'execution' && (
        <SideDrawer
          viewOnly={viewOnly}
          fileId={fileId}
          fileIds={fileIds}
          workStepsFileIds={props.workStepsFileIds}
          files={files}
          activeFile={file}
          fileColors={fileColors}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          onFileSelect={onFileSelected}
          onChooseStartingFile={chooseStartingFile}
          onFileRemove={onFileRemoved}
          onAddFile={onAddFile}
        />
      )}
    </FilePreviewWrapper>
  );
};

const FilePreviewWithProvider = (props: PnidPreviewProps) => {
  return (
    <Flex direction="column" style={{ width: '100%' }}>
      <PnidMiniWidget />
      <FilePreview {...props} />
    </Flex>
  );
};

export { FilePreviewWithProvider as PnidPreview };
