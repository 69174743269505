import React from 'react';
import { Body } from '@cognite/cogs.js';

import {
  StepBoxSwitchBody,
  ToggleWrapper,
  ToggleWrapperDefault,
  StepSwitch,
} from './elements';

export type Props = {
  isExecution?: boolean;
  showWorkSteps?: boolean;
  setShowWorkSteps: Function;
};

const StepBoxToggleContent = ({ showWorkSteps, setShowWorkSteps }: Props) => {
  return (
    <>
      <Body size="xx-small">Stepbox</Body>
      <StepBoxSwitchBody size="small">Show</StepBoxSwitchBody>
      <StepSwitch
        name="executionWorkstepVisibility"
        checked={showWorkSteps}
        data-testid="execution-workstep-visibility-actions-switch"
        onChange={(
          event: React.ChangeEvent<HTMLInputElement>,
          nextValue?: boolean
        ) => {
          setShowWorkSteps(nextValue || false);
        }}
        isExecution
      />
      <StepBoxSwitchBody size="small">Hide</StepBoxSwitchBody>
    </>
  );
};

export const StepBoxToggle = ({
  isExecution,
  showWorkSteps,
  setShowWorkSteps,
}: Props) => {
  return (
    <>
      {isExecution ? (
        <ToggleWrapper>
          <StepBoxToggleContent
            showWorkSteps={showWorkSteps}
            setShowWorkSteps={setShowWorkSteps}
            isExecution
          />
        </ToggleWrapper>
      ) : (
        <ToggleWrapperDefault>
          <StepBoxToggleContent
            showWorkSteps={showWorkSteps}
            setShowWorkSteps={setShowWorkSteps}
          />
        </ToggleWrapperDefault>
      )}
    </>
  );
};
