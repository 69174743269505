import styled from 'styled-components/macro';
import { Body, Switch } from '@cognite/cogs.js';
import zIndex from 'utils/zindex';

export const StepBoxSwitchBody = styled(Body)`
  margin-left: 10px;
`;

export const ToggleWrapper = styled.div.attrs({ className: 'custom-fixed' })`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  left: 45%;
  background-color: white;
  padding: 8px 13px 8px 13px;
  box-shadow: var(--cogs-z-8);
  z-index: ${zIndex.MAXIMUM};
`;

export const ToggleWrapperDefault = styled.div`
  display: flex;
  align-items: center;
`;

export const StepSwitch = styled(Switch)<{ isExecution: boolean }>`
  margin-left: ${(props) => (props.isExecution ? 10 : 0)}px;
`;
