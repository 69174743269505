import styled from 'styled-components/macro';
import { Colors } from '@cognite/cogs.js';

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 108px;
  user-select: none;

  background: ${Colors['decorative--grayscale--700']};
  color: ${Colors['text-icon--on-contrast--strong']};
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1), 0px 6px 15px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  width: 100%;
`;
