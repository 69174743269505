/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdInfo } from '@cognite/sdk';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: '',
    project: '',
    projectId: 0,
  },
  reducers: {
    login: (state, action: PayloadAction<IdInfo>) => {
      // eslint-disable-next-line prefer-object-spread
      return { ...state, ...action.payload };
    },
  },
});

export type AuthState = ReturnType<typeof authSlice.reducer>;
export const { actions } = authSlice;
export default authSlice;
